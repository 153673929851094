<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <Logins />
    </template>
    <template #fallback>
      <Spinner />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
const Logins = defineAsyncComponent(() =>
  import("@/components/Users/Logins.vue")
);
export default {
  name: 'Login',
  components: {
    Suspense,
    Spinner,
    AppHeader,
    AppFooter,
    Logins
  },
}
</script>